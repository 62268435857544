import React, { useEffect, useMemo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import posthog from 'posthog-js';

import Shell from './Shell';
import { AuthState } from '../services/authenticationService';
import useStyles from '../styles';
import { getBleSensors, getTempUser, getThemeMode } from '../state/selectors';
import { clearReduxStore, setTempUser } from '../state/actions';
import { Logo } from '../components/Logo';
import './amplify.css';
import { fetchTempUserToken } from '../services/apiService';
import { TempUser, ThemeMode } from '../state/types';
import { persistState, StorageTypes } from '../utils/persistentState';
import {
  loginComponents,
  formFields,
  parseJwt,
  tempUserExpiryStr,
  handleLogout,
  appSwitchBtnHandler,
} from './helpers';
import MobileAppIcon from '../styles/icons/MobileAppIcon';
import UserPolicyDialog from '../Widgets/Dialog/UserPolicyDialog';
import WebViewMessages from './WebViewMessages';

interface AuthShellProps {
  setThemeType: React.Dispatch<React.SetStateAction<ThemeMode>>;
}

function AuthShell({ setThemeType }: AuthShellProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { route } = useAuthenticator((context) => [context.route]);
  const { user } = useAuthenticator((context) => [context.user]);
  const bleSensors = useSelector(getBleSensors) ?? [];
  const [showUserPolicy, setShowUserPolicy] = useState(false);

  useEffect(() => {
    //  show user policy if user has logged in for the first time
    if (route === AuthState.ForceNewPassword) setShowUserPolicy(true);
  }, [route]);

  // Extract any temporary credential from URL
  const params = new URLSearchParams(window.location.search);
  const tempCredential = params.get('credential');
  const tempUser: TempUser | null = useSelector(getTempUser);

  const themeMode = useSelector(getThemeMode);
  const hasUser = user?.attributes?.email !== undefined;

  const userName = tempUser?.expiry
    ? tempUserExpiryStr(tempUser.expiry)
    : user?.attributes?.email ?? 'Unknown';

  // this disables tracking for localhost
  if (window.location.href.includes('localhost')) {
    // if tracking is needed posthog.opt_in_capturing() should be called
    posthog.opt_out_capturing();
  }

  useEffect(() => setThemeType(themeMode), [setThemeType, themeMode]);

  useEffect(() => {
    let userInfo;
    if (hasUser) userInfo = { userId: user?.attributes?.email ?? 'Unknown', userType: 'Full' };
    else if (tempUser) userInfo = { userId: tempUser.credential, userType: 'Temporary' };
    if (userInfo) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ReactNativeWebView?.postMessage(JSON.stringify({ userInfo }));
    }
  }, [hasUser, tempUser, user]);

  // Try to obtain accessToken for tempCredential
  useEffect(() => {
    if (tempCredential && !hasUser) {
      if (!tempUser || tempUser.credential !== tempCredential) {
        dispatch(clearReduxStore());
        fetchTempUserToken(tempCredential)
          .then((token) => {
            if (token?.access_token) {
              persistState('#', StorageTypes.CurrentLocation);
              const tokenDecoded = parseJwt(token.access_token);
              const tempUserNew: TempUser = {
                credential: tempCredential,
                accessToken: token.access_token,
                expiry: tokenDecoded.exp ?? 0,
              };
              // TODO work out if tempUser is sub-access of current full user
              dispatch(setTempUser(tempUserNew));
            }
          })
          .catch(() => {
            dispatch(setTempUser(null));
          });
      }
    } else if (hasUser) {
      dispatch(setTempUser(null));
    }
  }, [tempCredential, dispatch, hasUser, tempUser]);

  // Auto logout tempUser at expiry
  useEffect(() => {
    if (tempUser?.expiry) {
      setTimeout(() => handleLogout(tempUser, dispatch), tempUser.expiry * 1000 - Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempUser]);

  useEffect(() => {
    if (route === AuthState.Authenticated && user.attributes?.email !== undefined) {
      posthog.identify(
        user.username, // distinct_id, required
        { email: user.attributes.email, userType: 'full' } // $set_once, optional
      );
    }
  }, [route, user]);

  const showLoginForm = useMemo(() => {
    let showForm = false;
    if (!tempUser && !tempCredential && !(route === AuthState.Authenticated && hasUser)) {
      showForm = true;
    } else if (tempCredential) {
      if (!tempUser && route === AuthState.SetUp) showForm = true;
    }
    return showForm;
  }, [tempUser, tempCredential, route, hasUser]);

  return (
    <>
      <WebViewMessages /> {/* handle messages from app WebView */}
      {!showLoginForm ? (
        <>
          {showUserPolicy && (
            <UserPolicyDialog showUserPolicy closeDialog={() => setShowUserPolicy(false)} />
          )}
          <Shell userName={userName} />
          {/* Cookie banner for future use
        {posthog.has_opted_in_capturing() ? null : <PosthogBanner />}
      */}
        </>
      ) : (
        <main>
          <AppBar position="relative" className={classes.header}>
            <Toolbar>
              <Logo />
            </Toolbar>
          </AppBar>
          <div className={classes.amplifyContainer}>
            <Authenticator hideSignUp components={loginComponents} formFields={formFields} />
            {/* Check from ble sensors if the view is mobileApp */}
            {bleSensors.length > 0 && (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => appSwitchBtnHandler(dispatch)}
                style={{ width: '70%', margin: '20px auto' }}
                startIcon={<MobileAppIcon style={{ fontSize: '32px' }} />}
              >
                Scan Only Mode
              </Button>
            )}
          </div>
        </main>
      )}
    </>
  );
}

export default AuthShell;
