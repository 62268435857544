import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useStyles from '../../styles/dashboard';
import { VarName, varNameDetails } from '../../utils/varNames';
import {
  getActiveMarker,
  getBleLocSwitchStatus,
  getBleSensors,
  getClickedItem,
  getCurrentLocation,
  getHighlightedItem,
  getMotionThreshold,
  getSelectedLocationVarValues,
  getSelectedVars,
  getSensorsById,
  getSensorsByLocId,
  getSensorsByVarName,
  getUserPosition,
} from '../../state/selectors';
import SensorSunburst from '../Plots/SensorSunburst';
import SensorIcon from '../../styles/icons/SensorIcon';
import { DataTreeItem, SensorLatest } from '../../services/api';
import { themeProps } from '../../styles/theme';
import MotionRingPlot from '../Plots/MotionRingPlot';
import DoughnutPlot from '../Plots/DoughnutPlot';
import SourcesMenu from '../../components/SourcesMenu';
import {
  setActiveMarker,
  setClickedItem,
  setSelectedBand,
  setSensorVars,
} from '../../state/actions';
import { persistState, StorageTypes } from '../../utils/persistentState';
import { isDataExpired } from '../../utils/functions';
import { findNearestSensor } from '../../utils/sensors';
import { getDataBandParams } from '../../utils/dataBandParams';
import { getDataValueString } from '../HelperComponents/DataValueString';

interface DashboardRingPlotsProps {
  showHistory: () => void;
}

function DashboardRingPlots({ showHistory }: DashboardRingPlotsProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const bleSensors = useSelector(getBleSensors);
  const allowBleLocSwitch = useSelector(getBleLocSwitchStatus);
  const activeMarker = useSelector(getActiveMarker);
  const selectedVars = useSelector(getSelectedVars);
  const selectedVarValues = useSelector(getSelectedLocationVarValues);
  const highlightedItem = useSelector(getHighlightedItem);
  const clickedItem = useSelector(getClickedItem);
  const sensorsById = useSelector(getSensorsById);
  const sensorsByVarName = useSelector(getSensorsByVarName);
  const currentLocation = useSelector(getCurrentLocation);
  const locSensorIds = useSelector(getSensorsByLocId).get(currentLocation);
  const userPosition = useSelector(getUserPosition);
  const motionThreshold = useSelector(getMotionThreshold);

  const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'));
  const allowedPlotCount = isDesktopView && window.innerHeight >= 840 ? 6 : 4; // based on screen
  const activeItemId = highlightedItem.id ? highlightedItem.id : clickedItem.id;
  const activeSensor = sensorsById.get(activeItemId);

  // Are we allowed to automatically select nearest sensor
  const autoSensorSelection = allowBleLocSwitch && !!bleSensors && !!userPosition;

  const sourceList = useMemo(() => {
    let varList: VarName[] = [];
    // take allowed no of sources from selected vars list
    if (selectedVars.length >= allowedPlotCount) varList = selectedVars.slice(0, allowedPlotCount);
    else {
      // fill with default varname if selected vars is less than allowed plot count
      const vars: VarName[] = [];
      for (let i = 0; i < selectedVars.length; i++) {
        vars.push(selectedVars[i]);
      }
      if (vars.length < allowedPlotCount) {
        if (!isDesktopView) {
          // for mobile view add param if not 2, to allow gensture handling for empty corner
          if (selectedVars.length === 2) varList = vars;
          else varList = [...vars, ...[VarName.Unknown]];
        } else varList = [...vars, ...[VarName.Unknown]];
      }
    }
    return varList;
  }, [allowedPlotCount, isDesktopView, selectedVars]);

  const onCardCloseHandler = (varName: VarName) => {
    const newVars = selectedVars.filter((item) => item !== varName);
    dispatch(setSensorVars(newVars));
    persistState(newVars, StorageTypes.SelectedVars);
  };

  // show history of a targetSensor for selected varName
  const navigateToHistory = (varName: VarName, targetSensor: SensorLatest | undefined) => {
    dispatch(setActiveMarker(varName));
    dispatch(setSelectedBand(null));
    showHistory();
    if (targetSensor?.id && (!activeItemId || activeItemId !== targetSensor.id)) {
      dispatch(setClickedItem({ id: targetSensor.id, varName }));
    }
  };

  return (
    <Box className={classes.summaryCardsContainer}>
      {sourceList.map((varName) => {
        const Icon = varNameDetails[varName]?.icon ?? SensorIcon;
        const data: DataTreeItem[] = selectedVarValues.get(varName) ?? [];

        const allVarNameSensorIds =
          sensorsByVarName.get(varName)?.filter((id) => locSensorIds?.includes(id)) ?? [];

        let dataSensor: SensorLatest | undefined; // closest var sensor from activeSensor
        let isDifferentSensor = false; // if closest sensor with var data is different that activeSensor

        // set activeSensor as targetSensor if has the varData
        if (
          activeSensor &&
          allVarNameSensorIds.includes(activeSensor.id) &&
          !isDataExpired(activeSensor.data?.find((item) => item.varName === varName)?.time ?? 0)
        )
          dataSensor = activeSensor;
        else {
          // find closest sensor with valid var data
          const locSensors = allVarNameSensorIds
            .map((id) => sensorsById.get(id))
            .filter(
              (sensor): sensor is SensorLatest =>
                sensor !== undefined &&
                !isDataExpired(sensor.data?.find((item) => item.varName === varName)?.time ?? 0)
            );

          const targetPosition = autoSensorSelection ? userPosition : activeSensor?.position;
          const targetSensor = targetPosition
            ? findNearestSensor(locSensors, targetPosition)
            : undefined;
          if (targetSensor) {
            dataSensor = targetSensor;
            isDifferentSensor = true;
          }
        }

        const sensorDataItem = dataSensor?.data?.find((item) => item.varName === varName);
        const sensorDataValue = sensorDataItem?.value;
        const hasSensorValue = !!(sensorDataValue !== undefined);

        function showRingPlots() {
          switch (varName) {
            case VarName.ClientsBle:
            case VarName.ClientsWiFi:
            case VarName.EnergyInkWh:
              return <SensorSunburst data={data} varName={varName} showHistory={showHistory} />;
            case VarName.MotionEvent:
              return <MotionRingPlot showHistory={showHistory} />;
            default:
              return <DoughnutPlot varName={varName} showHistory={showHistory} />;
          }
        }

        return (
          <Card
            key={varName}
            className={classes.summaryCard}
            style={{
              borderBottom:
                varName === activeMarker ? `solid 5px ${themeProps.colors.alertYellow}` : 'none',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!isDesktopView && varName !== VarName.Unknown)
                navigateToHistory(varName, dataSensor);
              else if (varName !== activeMarker && varName !== VarName.Unknown)
                dispatch(setActiveMarker(varName));
            }}
          >
            {/* show close icon for desktop view */}
            {varName !== VarName.Unknown && isDesktopView && (
              <Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 100 }}>
                <CloseIcon
                  style={{ color: themeProps.colors.lightGrey, cursor: 'pointer' }}
                  onClick={() => onCardCloseHandler(varName)}
                />
              </Box>
            )}

            {/* source header label with icon */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                {Icon && <Icon />}
                <Typography variant={isDesktopView ? 'h5' : 'body1'} style={{ marginLeft: '5px' }}>
                  {varName === VarName.Unknown ? 'Add Source' : varNameDetails[varName].label}
                </Typography>
              </Box>
            </Box>

            {/* show sensor name if sensor is selected and is different from selected sensor */}
            {isDifferentSensor && !isDesktopView && activeSensor && (
              <Box sx={{ position: 'absolute', bottom: 10, left: 5 }}>
                <Typography variant="caption" style={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOnIcon /> {dataSensor?.name}
                </Typography>
              </Box>
            )}

            {/* body content with sensor reading or add source option */}
            <Box className={classes.dashboardRingPlot}>
              {activeSensor && hasSensorValue && !isDesktopView ? (
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      marginTop: '10px',
                      color: getDataBandParams(varName, sensorDataValue).color,
                    }}
                  >
                    {getDataValueString(
                      sensorDataValue,
                      varName,
                      sensorDataItem?.time,
                      motionThreshold
                    )}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ alignSelf: 'center' }}>
                  {varName === VarName.Unknown ? (
                    <SourcesMenu
                      iconOnlyBtn
                      customIcon={<AddIcon style={{ fontSize: isDesktopView ? '70px' : '40px' }} />}
                    />
                  ) : (
                    <Box sx={{ marginTop: { xs: '-12px', md: '-8px' } }}> {showRingPlots()}</Box>
                  )}
                </Box>
              )}
            </Box>

            <Box sx={{ cursor: 'pointer', position: 'absolute', bottom: 5, right: 5 }}>
              {varName !== VarName.Unknown && (
                <Button
                  onClick={() => navigateToHistory(varName, dataSensor)}
                  endIcon={<ArrowForwardIcon />}
                  sx={{ minWidth: '0' }}
                >
                  {isDesktopView ? 'Detail' : ''}
                </Button>
              )}
            </Box>
          </Card>
        );
      })}
    </Box>
  );
}

export default DashboardRingPlots;
