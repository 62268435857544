import React from 'react';
import { Marker } from 'react-map-gl/maplibre';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { VarName } from '../../../services/api';
import {
  getClickedItem,
  getHighlightedItem,
  getShowSensorLabels,
  getSelectedSensorIds,
} from '../../../state/selectors';
import { isDataExpired } from '../../../utils/functions';
import { getDataBandParams } from '../../../utils/dataBandParams';
import DataValueString from '../../HelperComponents/DataValueString';
import useStyles from '../../../styles';
import { setClickedItem, setHighlightedItem, setBleLocSwitchStatus } from '../../../state/actions';
import { varNameDetails } from '../../../utils/varNames';
import { themeProps } from '../../../styles/theme';
import { MarkerDataProps } from '../mapHelpers';

interface CustomMarkerProps {
  activeMarkers: MarkerDataProps[];
}

function CustomMarker({ activeMarkers }: CustomMarkerProps): JSX.Element {
  const highlightedItem = useSelector(getHighlightedItem);
  const clickedItem = useSelector(getClickedItem);
  const showSensorLabel = useSelector(getShowSensorLabels);
  const selectedSensorIds = useSelector(getSelectedSensorIds);
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;

  // highlighted item sometime stays even on mouse leave. Thus removing that when clicked outside
  const handleClickOutside = () => {
    dispatch(setHighlightedItem({ id: '' }));
  };

  const handleClickMarker = (id: string, varName: VarName | undefined) => {
    if (id === clickedItem?.id) {
      // Allow unselection of clickedItem
      dispatch(setClickedItem({ id: '' }));
    } else {
      dispatch(setClickedItem({ id, varName }));
    }
    // Clicking on markers overrides auto selection from ble position
    dispatch(setBleLocSwitchStatus(false));
  };

  const useOutsideClick = (callback: () => void) => {
    const ref = React.useRef();
    React.useEffect(() => {
      const handleClick = () => {
        callback();
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return ref;
  };

  const ref = useOutsideClick(handleClickOutside);

  const getBorderColor = (value: number, time: number, varName: VarName) => {
    if (time && value !== undefined) {
      if (varName !== VarName.OnlineStatus && time && isDataExpired(time)) {
        return themeProps.colors.lightGrey;
      }
      const c = getDataBandParams(varName, value ?? NaN);
      return c?.color;
    }
    // return default
    return themeProps.colors.lightGrey;
  };

  return (
    <>
      {activeMarkers.map((marker) => {
        const { id, value, time, position, varName, shortName } = marker;
        const color = getBorderColor(value, time, varName);
        const Icon = varNameDetails[varName].icon;
        const lat = position?.lat;
        const lng = position?.lng;
        const isSelected = selectedSensorIds.includes(id);
        const borderStyle = isSelected ? 'dashed' : 'solid';
        let zIndex = 5;
        if (id === activeItem.id) {
          zIndex = 7;
        } else if (isSelected) {
          zIndex = 6;
        }

        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <Box
            onMouseEnter={() => dispatch(setHighlightedItem({ id, varName }))}
            onMouseLeave={() => dispatch(setHighlightedItem({ id: '' }))}
            key={id}
            ref={ref}
          >
            {lat !== undefined && lng !== undefined && value !== undefined && time && (
              <Marker
                longitude={lng}
                latitude={lat}
                anchor="center"
                style={{ zIndex }}
                onClick={() => handleClickMarker(id, varName)}
              >
                {id !== activeItem.id && (
                  <div
                    className={classes.marker}
                    style={{
                      border: `${borderStyle} 5px ${color}dd`,
                    }}
                  >
                    <Typography variant="body1" className={classes.markerContent}>
                      {showSensorLabel ? (
                        shortName
                      ) : (
                        <DataValueString
                          value={value}
                          varName={varName}
                          showMetric={false}
                          time={time}
                        />
                      )}
                    </Typography>
                  </div>
                )}
                {id === activeItem.id && (
                  <div
                    className={classes.highLightedMarker}
                    style={{ border: `${borderStyle} 12px ${color}dd` }}
                  >
                    <Box className={classes.markerContent}>
                      <Typography
                        variant="body1"
                        style={{
                          color: 'white',
                          textAlign: 'center',
                          fontWeight: themeProps.fontWeight.semiBold,
                        }}
                      >
                        {shortName}
                      </Typography>
                      <hr style={{ width: '80%' }} />
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {Icon && <Icon fontSize="small" />}
                        <Typography variant="body1" style={{ marginLeft: '5px', color: 'white' }}>
                          <DataValueString
                            value={value}
                            varName={varName}
                            showMetric={false}
                            time={time}
                          />
                        </Typography>
                        <Typography variant="body2" style={{ marginLeft: '5px', color: 'white' }}>
                          {varNameDetails[varName].metric}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                )}
              </Marker>
            )}
          </Box>
        );
      })}
    </>
  );
}

export default CustomMarker;
