import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';
import useGlobalStyles from '../styles';
import { PropLocationItem, PropSensorItem } from '../services/api/api';
import LocationHeader from '../Widgets/LocationConfig/LocationHeader';
import { AlertMsg, LocationContent } from '../Widgets/LocationConfig/LocationProperties';
import {
  getCurrentLocation,
  getCurrentLocationChildSensors,
  getCurrentLocationChildLocations,
} from '../state/selectors';
import {
  setCurrentLocation,
  setLocationDirectChildren,
  setSensorsById,
  updateLocationData,
} from '../state/actions';
import { StorageTypes } from '../utils/persistentState';
import { fetchLocationDirectChildren, fetchLocationProperties } from '../services/apiService';
import LocationSensorsList from '../Widgets/LocationConfig/LocationSensorsList';
import UsersInLocation from '../Widgets/LocationConfig/UsersInLocation';
import SubLocationsList from '../Widgets/LocationConfig/SubLocationsList';
import LocationDetails from '../Widgets/LocationConfig/LocationDetails';
import { themeProps } from '../styles/theme';

function LocationConfig(): JSX.Element {
  const globalClasses = useGlobalStyles();
  const { locationId } = useParams() as { locationId: string };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const locations = useSelector(getCurrentLocationChildLocations);
  const locSensors = useSelector(getCurrentLocationChildSensors);
  const [childLocations, setChildLocations] = useState<PropLocationItem[]>();
  const [sensorsInLocation, setSensorsInLocation] = useState<PropSensorItem[]>();
  const [fetchingLocationDetails, setFetchingLocationDetails] = useState<boolean>(true);
  const [hasUsers, setHasUsers] = useState<boolean>();
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [isValidLocation, setIsValidLocation] = useState<boolean>();
  const [locationDetails, setLocationDetails] = useState<PropLocationItem>();
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [currentContent, setCurrentContent] = useState<LocationContent>(
    LocationContent.subLocations
  );
  const [locId, setLocId] = useState<string>('');
  const currentStateLocation = useSelector(getCurrentLocation);
  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
  const firstRender = useRef(true);
  const urlLocId = decodeURIComponent(locationId);

  useEffect(() => {
    // update current location store on url change for uniform browser history
    if (urlLocId !== currentStateLocation) {
      dispatch(setCurrentLocation(urlLocId));
    }
    // eslint-disable-next-line
  }, [urlLocId]);

  useEffect(() => {
    if (firstRender.current) {
      // take url Id as location id for first render
      if (urlLocId) {
        setLocId(urlLocId);
      }
      firstRender.current = false;
    } else if (currentStateLocation) {
      // for all other renders take id from store
      if (currentStateLocation !== urlLocId)
        navigate(`/location/${encodeURIComponent(currentStateLocation)}`);
      setLocId(currentStateLocation);
    }
    // eslint-disable-next-line
  }, [currentStateLocation]);

  useEffect(() => {
    setAlertMsg({ success: true, msg: '', alertType: 'success' });
    if (childLocations !== undefined && childLocations.length === 0) {
      if (sensorsInLocation !== undefined && sensorsInLocation.length === 0) {
        setCurrentContent(LocationContent.info);
      } else setCurrentContent(LocationContent.subSensors);
    } else {
      setCurrentContent(LocationContent.subLocations);
    }
  }, [locId, childLocations, sensorsInLocation]);

  const getLocationProperties = () => {
    if (locId) {
      setFetchingLocationDetails(true);
      fetchLocationProperties(locId)
        .then((res) => {
          if (res.id) {
            setLocationDetails(res);
            setIsValidLocation(true);
            dispatch(updateLocationData(res));
          }
          setFetchingLocationDetails(false);
        })
        .catch((err) => {
          setIsValidLocation(false);
          setAlertMsg({
            success: true,
            msg: err.cause,
            alertType: 'error',
          });
        });
    }
  };

  useEffect(() => {
    if (locations) {
      const sortedLocations = [...locations].sort((a, b) => {
        const nameA = a.shortName ?? '';
        const nameB = b.shortName ?? '';
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      });
      setChildLocations(sortedLocations);
      setContentLoading(false);
    }
    if (locSensors) {
      const sortedSensors = [...locSensors].sort((a, b) => {
        const nameA = a.name ?? '';
        const nameB = b.name ?? '';
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      });
      setSensorsInLocation(sortedSensors);
    }
  }, [locSensors, locations]);

  useEffect(() => {
    setAlertMsg({ success: true, msg: '', alertType: 'info' });
    localStorage.setItem(StorageTypes.CurrentLocation, JSON.stringify(locId));
    if (currentStateLocation !== locId) dispatch(setCurrentLocation(locId));
    getLocationProperties();
    // eslint-disable-next-line
  }, [locId]);

  const updateLocationChildren = () => {
    fetchLocationDirectChildren(urlLocId)
      .then((res) => {
        dispatch(setLocationDirectChildren(res.locations ?? []));
        dispatch(setSensorsById(res.sensors ?? []));
      })
      .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
  };

  const showSubLocations = (locationProps: PropLocationItem) => (
    <Paper elevation={0} sx={{ marginTop: { xs: '1rem', md: 0 } }}>
      <SubLocationsList
        locationDetails={locationProps}
        sortedChildLocations={childLocations}
        updateLocationChildren={updateLocationChildren}
        loadingLocations={contentLoading}
      />
    </Paper>
  );

  const showSensorsList = (id: string) => (
    <Paper elevation={0} sx={{ marginTop: '1rem' }}>
      <LocationSensorsList
        locationId={id}
        locationSensors={sensorsInLocation}
        sensorsLoading={contentLoading}
      />
    </Paper>
  );

  const showUsersInLocation = () => (
    <Paper elevation={0} sx={{ marginTop: '1rem' }}>
      <UsersInLocation
        locationId={locId}
        currentContent={currentContent}
        isDesktopView={isDesktopView}
        setHasUsers={setHasUsers}
      />
    </Paper>
  );

  const showLocationDetails = (location: PropLocationItem) => (
    <Paper elevation={0} sx={{ marginTop: { xs: '1rem', lg: 0 } }}>
      <LocationDetails
        locationDetails={location}
        setLocationDetails={setLocationDetails}
        alertMsg={alertMsg}
        setAlertMsg={setAlertMsg}
        isAllowedToDelete={
          childLocations?.length === 0 && sensorsInLocation?.length === 0 && !hasUsers
        }
      />
    </Paper>
  );

  const showDesktopContent = (location: PropLocationItem) => (
    <>
      <Grid item sm={12} md={6} style={{ paddingLeft: '0' }}>
        {showLocationDetails(location)}
        {showUsersInLocation()}
      </Grid>
      <Grid item sm={12} md={6}>
        {showSubLocations(location)}
        {showSensorsList(location.id)}
      </Grid>
    </>
  );

  const showMobileContent = (location: PropLocationItem) => (
    <Grid item xs={12}>
      {currentContent === LocationContent.info && showLocationDetails(location)}
      {currentContent === LocationContent.subSensors && showSensorsList(location.id)}
      {currentContent === LocationContent.subLocations && showSubLocations(location)}
      {currentContent === LocationContent.users && showUsersInLocation()}
    </Grid>
  );

  const redirectToMain = () => {
    dispatch(setCurrentLocation('#'));
    navigate('/location/%23');
  };

  return (
    <div className={globalClasses.bodyContent}>
      {!isValidLocation && !fetchingLocationDetails && (
        <Box mt={2}>
          <Alert severity="error" className={globalClasses.alertMsg}>
            Location not found
          </Alert>
          <Button
            onClick={redirectToMain}
            variant={themeProps.btnVariant.default}
            style={{ textTransform: 'capitalize', margin: '20px' }}
          >
            Go to Main Location Page
          </Button>
        </Box>
      )}
      {locationDetails && isValidLocation && (
        <>
          <LocationHeader
            childLocations={childLocations}
            sensorsInLocation={sensorsInLocation}
            currentContent={currentContent}
            setCurrentContent={setCurrentContent}
            isDesktopView={isDesktopView}
          />
          <Grid
            container
            spacing={isDesktopView ? 1 : 0} // optimize space in small screen
            style={{ margin: '5px 0', width: '100%' }}
          >
            {isDesktopView
              ? showDesktopContent(locationDetails)
              : showMobileContent(locationDetails)}
          </Grid>
        </>
      )}
    </div>
  );
}

export default LocationConfig;
