import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  bodyContent: {
    [theme.breakpoints.down('md')]: {
      height: '100%',
      display: 'flex',
    },
  },
  dashboard: {
    overflow: 'hidden',
    display: 'flex',
    height: '100% !important',
    textAlign: 'center',
  },
  dashboardTile: {
    flex: '40%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  content: {
    flex: '60%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60%',
      margin: 'auto',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
}));

export default useStyles;
