import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RoomIcon from '@mui/icons-material/Room';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ColouredLinePlot from '../Plots/ColouredLinePlot';
import { DataTreeItem } from '../../services/api';
import {
  getActiveMarker,
  getClickedItem,
  getHighlightedItem,
  getLocationsById,
  getMotionThreshold,
  getSelectedSensorIds,
  getSensorsById,
} from '../../state/selectors';
import DataStore from '../../services/dataStore';
import { SensorHistoryPlotItem } from '../Plots/plotCommon';
import { getDataBandParams } from '../../utils/dataBandParams';
import SensorIcon from '../../styles/icons/SensorIcon';
import { setClickedItem, setCurrentLocation } from '../../state/actions';
import DataValueString, { getDataValueNumber } from '../HelperComponents/DataValueString';
import { getShortTimeStr } from '../../utils/functions';
import { themeProps } from '../../styles/theme';
import BandValueCheckbox, { toggleCheckBox } from '../HelperComponents/BandValueCheckbox';

interface SensorListItemProps {
  sensorData: DataTreeItem;
  isOnline: boolean;
}

function SensorListItem({ sensorData, isOnline }: SensorListItemProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dataStore = DataStore.getInstance();
  const sensorsById = useSelector(getSensorsById);
  const locationsById = useSelector(getLocationsById);
  const activeMarker = useSelector(getActiveMarker);
  const selectedSensorIds = useSelector(getSelectedSensorIds);
  const rangeValue = useSelector(getMotionThreshold);
  const highlightedItem = useSelector(getHighlightedItem);
  const clickedItem = useSelector(getClickedItem);
  const [sensorPlotData, setSensorPlotData] = useState<SensorHistoryPlotItem>();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const selectedSensorId = highlightedItem?.id ? highlightedItem.id : clickedItem?.id;

  const { id, varName, location } = sensorData;

  const onMenuClick = (navUrl: string) => {
    setMenuAnchor(null);
    window.open(navUrl, '_blank');
  };

  useEffect(() => {
    dataStore.getHistory(id, varName).then((history) => {
      const plotDataItem: SensorHistoryPlotItem = {
        varName: activeMarker,
        sensorId: id,
        sensorName: dataStore.getDetails(id)?.name ?? '',
        history: {
          time: history.map(({ time }) => time),
          value: history.map(({ value }) => value),
        },
      };
      setSensorPlotData(plotDataItem);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, dataStore, sensorData, rangeValue]);

  const { color } = getDataBandParams(varName, getDataValueNumber(sensorData, rangeValue) ?? 0);

  const goToLocation = (locationId: string) => {
    setMenuAnchor(null);
    dispatch(setCurrentLocation(locationId));
  };

  return (
    <ListItemButton
      style={{
        display: 'flex',
        backgroundColor:
          id === selectedSensorId
            ? `${isOnline ? color : themeProps.colors.white}66`
            : theme.palette.primary.light,
        borderRadius: '8px',
        padding: '5px 0',
      }}
      onClick={() => {
        dispatch(setClickedItem({ id, varName: activeMarker }));
        toggleCheckBox(id, selectedSensorIds, dispatch);
      }}
    >
      <Grid container spacing={1}>
        <Grid item sm={1} xs={1} style={{ alignSelf: 'center' }}>
          <BandValueCheckbox sensorId={id} color={color} />
        </Grid>
        <Grid item sm={2} xs={4} style={{ alignSelf: 'center' }}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h5" style={{ alignSelf: 'center', marginLeft: '10px' }}>
              {sensorData.value !== undefined && (
                <DataValueString
                  value={sensorData.value}
                  varName={activeMarker}
                  time={sensorData.time}
                />
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={1} xs={2} style={{ alignSelf: 'center' }}>
          <span>{sensorData.time && getShortTimeStr(sensorData.time)}</span>
        </Grid>

        <Grid item sm={3} xs={5} style={{ alignSelf: 'center' }}>
          <div style={{ display: 'flex' }}>
            <Typography variant="body1" style={{ marginLeft: '10px', alignSelf: 'center' }}>
              {`${sensorsById.get(id)?.name ?? id} - ${
                locationsById.get(location ?? '')?.name ?? ''
              }`}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={4} xs={9}>
          {sensorPlotData && (
            <Box>
              <ColouredLinePlot data={sensorPlotData} inlinePlot transparentBg staticPlot />
            </Box>
          )}
        </Grid>

        <Grid item sm={1} xs={1} style={{ textAlign: 'end', alignSelf: 'center' }}>
          <IconButton
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            sx={{ background: 'transparent' }}
          >
            <MoreHorizIcon />
          </IconButton>
        </Grid>
        <Menu
          id="settings-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuList sx={{ padding: 0 }}>
            <MenuItem onClick={() => onMenuClick(`/sensors/${id}`)}>
              <SensorIcon />
              <span style={{ marginLeft: '5px' }}>Sensor Info</span>
            </MenuItem>
            <MenuItem onClick={() => goToLocation(location ?? '#')}>
              <RoomIcon />
              <span style={{ marginLeft: '5px' }}>Go to Location</span>
            </MenuItem>
            <MenuItem
              onClick={() => onMenuClick(`/location/${encodeURIComponent(location ?? '#')}`)}
            >
              <RoomIcon />
              <span style={{ marginLeft: '5px' }}>Location Info</span>
            </MenuItem>
          </MenuList>
        </Menu>
      </Grid>
    </ListItemButton>
  );
}

export default SensorListItem;
