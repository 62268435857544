import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ClearIcon from '@mui/icons-material/Clear';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useStyles from '../styles';
import SubSensors from '../Widgets/SensorConfig/SubSensors';
import AccessPoints from '../Widgets/SensorConfig/AccessPoints';
import SensorData from '../Widgets/SensorConfig/SensorData';
import SensorDetails from '../Widgets/SensorConfig/SensorDetails/SensorDetails';
import {
  SensorLatest,
  VisibleSubSensor,
  BaseAccessPoint,
  WiFiConfig,
  GatewaySensorType,
  BacnetSettings,
} from '../services/api/api';
import {
  deleteSensor,
  fetchSensorLatest,
  fetchVisibleItems,
  getSensorBacnetConfig,
  updateSensorProperties,
} from '../services/apiService';
import Loading from '../components/Loading';
import {
  filterHighStrengthGateway,
  GatewayVisibleSubsensors,
  getAllGatewayVisibleSubsensors,
} from '../Widgets/SensorConfig/helpers';
import { AlertMsg } from '../Widgets/LocationConfig/LocationProperties';
import RemoveDialog from '../Widgets/Dialog/RemoveDialog';
import { themeProps } from '../styles/theme';
import Header from '../components/Header';
import SensorIcon from '../styles/icons/SensorIcon';
import { sensorTypeDetails } from '../utils/sensorProperties';
import WifiIcon from '../styles/icons/WifiIcon';
import { setSensorsById, removeSensorData } from '../state/actions';
import { getSensorsById, getSensorsByLocId } from '../state/selectors';

function SensorConfig(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sensorId } = useParams() as { sensorId: string };
  const sensorsByLocId = useSelector(getSensorsByLocId);
  const sensorsById = useSelector(getSensorsById);

  const [bacnetConfig, setBacnetConfig] = useState<BacnetSettings>();
  const [isBacnetEditable, setIsBacnetEditable] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [fetchingVisibleItems, setFetchingVisibleItems] = useState<boolean>(false);
  const [sensorDetails, setSensorDetails] = useState<SensorLatest>();
  const [subSensors, setSubSensors] = useState<VisibleSubSensor[]>();
  const [accessPoints, setAccessPoints] = useState<BaseAccessPoint[]>();
  const [wifiConfig, setWifiConfig] = useState<WiFiConfig>();
  const [sensorExists, setSensorExists] = useState<boolean>(true);
  const [changingSensorStatus, setChangingSensorStatus] = useState<boolean>(false);
  const [sensorIsGateway, setSensorIsGateway] = useState<boolean>(true);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [sensorStatusAlert, setSensorStatusAlert] = useState<AlertMsg>();
  const [bacnetAlertMsg, setBacnetAlertMsg] = useState('');
  const [allGatewayVisibleSubSensors, setAllGatewayVisibleSubSensors] =
    useState<GatewayVisibleSubsensors[]>();
  const isEnabledSensor = !sensorIsGateway && sensorDetails?.gateway !== '';
  const locationId = sensorDetails?.location;

  const showHeaderTitle = () => {
    const Icon = sensorIsGateway
      ? WifiIcon
      : (sensorDetails?.type && sensorTypeDetails[sensorDetails.type]?.icon) ?? SensorIcon;

    const sensorType = sensorIsGateway
      ? 'Gateway Sensor'
      : sensorDetails?.type && sensorTypeDetails[sensorDetails.type]?.label;
    const sensorTitle = sensorDetails ? `${sensorDetails.name} - ${sensorType}` : sensorId;

    return (
      <Box sx={{ display: 'flex', padding: '8px' }}>
        <Icon />
        <Typography variant="h6" sx={{ marginLeft: '10px' }}>
          {sensorTitle}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    getSensorBacnetConfig(sensorId)
      .then((details) => {
        setBacnetConfig(details);
        setIsBacnetEditable(true);
      })
      .catch((err) => {
        setBacnetAlertMsg(err?.cause?.msg ?? '');
        setIsBacnetEditable(false);
        const resStatus = err?.cause?.status ?? undefined;
        if (resStatus && resStatus === 402) setIsBacnetEditable(false);
        else if (resStatus && resStatus === 404) setIsBacnetEditable(true);
      });
  }, [sensorId]);

  useEffect(() => {
    setAllGatewayVisibleSubSensors([]);
  }, [locationId]);

  const getVisibleItems = useCallback(() => {
    setFetchingVisibleItems(true);
    fetchVisibleItems(sensorId)
      .then((response) => {
        setFetchingVisibleItems(false);
        setSubSensors(response.subsensors);
        setAccessPoints(response.wifi_aps);
        setWifiConfig(response.wifi_config);
      })
      .catch((err) => {
        setSensorStatusAlert({ success: true, msg: err.cause, alertType: 'error' });
        setFetchingVisibleItems(false);
      });
  }, [sensorId]);

  const getSensorLatest = useCallback(() => {
    fetchSensorLatest(sensorId)
      .then((details) => {
        dispatch(setSensorsById([details]));
        const isGateway = Object.values(GatewaySensorType).includes(
          details?.type as GatewaySensorType
        );
        setSensorDetails(details);
        setPageLoading(false);
        if (isGateway) {
          setSensorIsGateway(true);
          getVisibleItems();
        } else {
          setSensorIsGateway(false);
        }
        setSensorExists(true);
      })
      .catch(() => {
        setPageLoading(false);
        setSensorExists(false);
      });
  }, [dispatch, getVisibleItems, sensorId]);

  useEffect(() => {
    setSensorExists(false);
    setPageLoading(true);
    getSensorLatest();
  }, [getSensorLatest, sensorId]);

  const updateSensorStatus = (gatewayId: string) => {
    const gatewayStatus = {
      gateway: gatewayId,
    };
    setChangingSensorStatus(true);

    updateSensorProperties(sensorId, gatewayStatus)
      .then(() => {
        setSensorStatusAlert({
          success: true,
          msg: 'Request successful, wait a few seconds for changes to take effect.',
          alertType: 'success',
        });
        setTimeout(() => {
          getSensorLatest();
          setChangingSensorStatus(false);
        }, 5000);
      })
      .catch((err) => {
        setSensorStatusAlert({ success: true, msg: err.cause, alertType: 'error' });
      });
  };

  const updateWithHighStrengthGateway = (allVisibleSubsensors: GatewayVisibleSubsensors[]) => {
    filterHighStrengthGateway(allVisibleSubsensors, sensorId).then((res) => {
      if (res && res.id !== '') {
        updateSensorStatus(res.id);
      } else {
        setSensorStatusAlert({
          success: true,
          msg: 'High Strength Gateway Not Found',
          alertType: 'error',
        });
      }
    });
  };

  const changeSensorStatus = () => {
    setSensorStatusAlert({ success: true, msg: '', alertType: 'success' });
    if (!isEnabledSensor && locationId) {
      if (allGatewayVisibleSubSensors && allGatewayVisibleSubSensors.length > 0) {
        updateWithHighStrengthGateway(allGatewayVisibleSubSensors);
      } else {
        getAllGatewayVisibleSubsensors(locationId, sensorsById, sensorsByLocId).then(
          (allVisibleSubsensors) => {
            setAllGatewayVisibleSubSensors(allVisibleSubsensors);
            updateWithHighStrengthGateway(allVisibleSubsensors);
          }
        );
      }
    } else if (isEnabledSensor) {
      const id = '';
      updateSensorStatus(id);
    }
  };

  const removeSensor = () => {
    setSensorStatusAlert({ success: true, msg: '', alertType: 'success' });
    deleteSensor(sensorId, true)
      .then(() => {
        dispatch(removeSensorData(sensorId));
        navigate(`/location/${encodeURIComponent(locationId ?? '#')}`);
        setShowDialog(false);
      })
      .catch((err) => {
        setSensorStatusAlert({
          success: false,
          msg: err.cause,
          alertType: 'error',
        });
        setShowDialog(false);
      });
  };

  const hasEnabledSubSensor = useMemo(() => {
    const enabledSubSensors = subSensors?.filter((sensor) => sensor.status === 'enabled');
    if (enabledSubSensors && enabledSubSensors.length > 0) return true;
    return false;
  }, [subSensors]);

  const delBtnTooltip = useMemo(() => {
    let label = 'Delete sensor';
    if (sensorIsGateway && hasEnabledSubSensor) label = 'Base sensor has enabled sub sensors';
    else if (isEnabledSensor) label = 'Sensor is enabled. Disable first to allow deletion';
    return label;
  }, [hasEnabledSubSensor, isEnabledSensor, sensorIsGateway]);

  return (
    <div className={`${classes.bodyContent} ${classes.sensorGrid}`} style={{ height: 'auto' }}>
      <RemoveDialog
        showDialog={showDialog}
        title={sensorId}
        removeHandler={removeSensor}
        cancelHandler={() => setShowDialog(false)}
        helperText="This will permanently delete all sensor data. Note: This operation may take some time to complete, you will not be able to create this sensor again during this time."
      />
      <Header>{showHeaderTitle()}</Header>
      <Divider style={{ margin: '5px 0' }} />
      {sensorExists && sensorDetails ? (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper elevation={0}>
                <SensorDetails
                  sensorId={sensorId}
                  sensorDetails={sensorDetails}
                  bacnetConfig={bacnetConfig}
                  isBacnetEditable={isBacnetEditable}
                  bacnetAlertMsg={bacnetAlertMsg}
                />
                {sensorStatusAlert?.msg && (
                  <Alert
                    severity={sensorStatusAlert?.alertType}
                    style={{ margin: '0 1rem 1rem 1rem' }}
                  >
                    {sensorStatusAlert.msg}
                  </Alert>
                )}
                <div style={{ display: 'flex' }}>
                  {!sensorIsGateway && (
                    <Button
                      color="primary"
                      variant={themeProps.btnVariant.default}
                      style={{ margin: '0 0 10px 15px', padding: '5px 20px' }}
                      startIcon={
                        changingSensorStatus ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <PowerSettingsNewIcon />
                        )
                      }
                      disabled={changingSensorStatus}
                      onClick={changeSensorStatus}
                    >
                      {isEnabledSensor ? 'Disable' : 'Enable'}
                    </Button>
                  )}
                  <Tooltip title={delBtnTooltip} placement="top-start">
                    <div>
                      <Button
                        color="secondary"
                        variant={themeProps.btnVariant.default}
                        style={{ margin: '0 0 10px 15px', padding: '5px 20px' }}
                        startIcon={<ClearIcon />}
                        onClick={() => setShowDialog(true)}
                        disabled={
                          (!sensorIsGateway && isEnabledSensor) ||
                          (sensorIsGateway && hasEnabledSubSensor)
                        }
                      >
                        Delete
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SensorData sensorDetails={sensorDetails} />
            </Grid>
          </Grid>
          {sensorIsGateway && subSensors && subSensors?.length > 0 && (
            <Box sx={{ marginTop: '5px' }}>
              <SubSensors
                sensorGatewayId={sensorId}
                sensorGatewayDetails={sensorDetails}
                subSensors={subSensors}
                refreshVisibleItems={getVisibleItems}
                bacnetConfig={bacnetConfig}
              />
            </Box>
          )}
          {sensorIsGateway && accessPoints && accessPoints.length > 0 && wifiConfig && (
            <AccessPoints
              accessPoints={accessPoints}
              wifiConfig={wifiConfig}
              refreshVisibleItems={getVisibleItems}
              sensorGatewayId={sensorId}
            />
          )}
          {fetchingVisibleItems && <Loading />}
        </>
      ) : (
        <>
          {pageLoading && <Loading />}
          {!pageLoading && (
            <Alert severity="error" className={classes.alertMsg}>
              Sensor not found
            </Alert>
          )}
        </>
      )}
    </div>
  );
}

export default SensorConfig;
