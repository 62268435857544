import React from 'react';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import {
  getActiveMarker,
  getLocSensorStatusData,
  getSelectedSensorIds,
} from '../../state/selectors';
import { themeProps } from '../../styles/theme';
import { persistState, StorageTypes } from '../../utils/persistentState';
import { setSelectedSensors } from '../../state/actions';
import { ActionTypes } from '../../state/actionTypes';
import { checkOnlineStatus } from '../../utils/sensors';

interface BandValueCheckboxProps {
  sensorId: string;
  color: string;
}

// add/remove sensors from selected sensors list
export const toggleCheckBox = (
  sensorId: string,
  selectedSensorIds: string[],
  dispatch: Dispatch<ActionTypes>
) => {
  const currentIndex = selectedSensorIds.indexOf(sensorId);
  const newSelectedSensorIds = [...selectedSensorIds];

  if (currentIndex === -1) {
    newSelectedSensorIds.push(sensorId);
  } else {
    newSelectedSensorIds.splice(currentIndex, 1);
  }

  persistState(newSelectedSensorIds, StorageTypes.SelectedPlotSensors);
  dispatch(setSelectedSensors(newSelectedSensorIds));
};

function BandValueCheckbox({ sensorId, color }: BandValueCheckboxProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();

  const locSensorStatusData = useSelector(getLocSensorStatusData);
  const activeMarker = useSelector(getActiveMarker);
  const selectedSensorIds = useSelector(getSelectedSensorIds);

  const isOnline = checkOnlineStatus(locSensorStatusData, sensorId, activeMarker);

  return (
    <Checkbox
      icon={
        <CircleIcon
          style={{
            color: 'transparent',
            borderRadius: '50%',
            border: `solid 4px ${isOnline ? color : themeProps.colors.lightGrey}`,
          }}
        />
      }
      checkedIcon={
        <CheckIcon
          style={{
            color: theme.palette.text.primary,
            borderRadius: '50%',
            border: `dashed 4px ${isOnline ? color : themeProps.colors.lightGrey}`,
          }}
        />
      }
      checked={selectedSensorIds.indexOf(sensorId) !== -1}
      onChange={() => toggleCheckBox(sensorId, selectedSensorIds, dispatch)}
    />
  );
}

export default BandValueCheckbox;
