import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import useStyles from '../../styles/navigationDrawer';
import { MobileLogo } from '../../components/Logo';
import { UserAdminTypes } from '../../services/authenticationService';
import navList, { NavListLabel } from './helpers';
import {
  getBleSensors,
  getCurrentLocation,
  getSelectedSensorIds,
  getShowNavDrawer,
  getTempUser,
  getThemeMode,
  getUserIsAdmin,
} from '../../state/selectors';
import { setShowNavDrawer, setThemeMode } from '../../state/actions';
import { appSwitchBtnHandler, handleLogout } from '../../Shell/helpers';
import { ThemeMode } from '../../state/types';
import MobileAppIcon from '../../styles/icons/MobileAppIcon';

function MobileViewDrawer(): JSX.Element {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUserAdmin = useSelector(getUserIsAdmin);
  const sensorsCompared = useSelector(getSelectedSensorIds);
  const locationId = useSelector(getCurrentLocation);
  const showNavDrawer = useSelector(getShowNavDrawer);
  const tempUser = useSelector(getTempUser);
  const bleSensors = useSelector(getBleSensors);

  return (
    <SwipeableDrawer
      anchor="left"
      open={showNavDrawer}
      onClose={() => dispatch(setShowNavDrawer(false))}
      onOpen={() => dispatch(setShowNavDrawer(true))}
      className={classes.mobileViewNavDrawer}
      classes={{
        paper: classes.mobileViewNavDrawerPaper,
      }}
      style={{ zIndex: 100 }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <MobileLogo />
        <IconButton
          aria-label="Close drawer"
          data-testid="close"
          onClick={() => dispatch(setShowNavDrawer(false))}
          size="large"
        >
          <CloseIcon color="secondary" />
        </IconButton>
      </Toolbar>
      <nav className={classes.mobileViewNavListContainer}>
        {navList.map((navMenu) => (
          <NavLink
            key={navMenu.id}
            to={
              navMenu.label === NavListLabel.config
                ? `/location/${encodeURIComponent(locationId)}`
                : navMenu.url
            }
            className={({ isActive }) =>
              [classes.mobileViewList, isActive ? classes.navLinkActive : null]
                .filter(Boolean)
                .join(' ')
            }
            onClick={() => dispatch(setShowNavDrawer(false))}
            style={{
              display: navMenu?.accessLevel === UserAdminTypes.admin && !isUserAdmin ? 'none' : '',
            }}
          >
            <Badge
              badgeContent={sensorsCompared.length > 0 && sensorsCompared.length}
              invisible={!(navMenu.label === NavListLabel.compare && sensorsCompared.length > 0)}
              color="error"
              classes={{ badge: classes.customBadge }}
            >
              <navMenu.icon className={classes.mobileViewNavIcon} />
            </Badge>
            {navMenu.label}
          </NavLink>
        ))}
        <hr />
        <NavLink
          to="/userProfile"
          className={({ isActive }) =>
            [classes.mobileViewList, isActive ? classes.navLinkActive : null]
              .filter(Boolean)
              .join(' ')
          }
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => dispatch(setShowNavDrawer(false))}
        >
          <PersonIcon className={classes.mobileViewNavIcon} />
          User Profile
        </NavLink>
      </nav>
      {/* Check from ble sensors if the view is mobileApp */}
      {bleSensors && (
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => appSwitchBtnHandler(dispatch)}
          style={{ width: '50%', margin: '0 auto' }}
          startIcon={<MobileAppIcon style={{ fontSize: '32px' }} />}
        >
          Scan Only Mode
        </Button>
      )}
      <Button
        color="secondary"
        variant="outlined"
        onClick={() =>
          dispatch(setThemeMode(themeMode === ThemeMode.dark ? ThemeMode.light : ThemeMode.dark))
        }
        style={{ width: '50%', margin: '10px auto' }}
      >
        {themeMode === ThemeMode.dark ? 'Light Theme' : 'Dark Theme'}
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        style={{ width: '50%', margin: '10px auto' }}
        onClick={() => handleLogout(tempUser, dispatch)}
        data-testid="logout-btn"
      >
        Logout
      </Button>
    </SwipeableDrawer>
  );
}

export default MobileViewDrawer;
