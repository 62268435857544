import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  dashboardContent: {
    position: 'absolute',
    top: '72px',
    left: '10px',
    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: 0,
      width: '100%',
    },
  },

  summaryCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 'calc(100vh - 72px)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      height: 'auto',
      padding: '0 8px',
    },
  },

  summaryCard: {
    opacity: '0.85',
    height: '240px',
    width: '250px',
    position: 'relative',
    zIndex: 10,
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
    marginBottom: 16,
    marginRight: 16,
    padding: 8,
    [theme.breakpoints.down('sm')]: {
      margin: '8px',
      height: '100px',
      width: 'calc(100vw/2 - 24px)',
    },
  },

  dashboardRingPlot: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },

  sensorHistoryContainer: {
    height: 'calc(100vh - 72px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      overflowY: 'unset',
    },
  },

  floatingBox: {
    width: '100%',
    boxShadow: '0 1px 6px 1px #00000091',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },

  floatingHeader: {
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    padding: '10px',
    background: 'none',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
    },
  },

  appSwitchBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 12,
  },

  pillBandLabel: {
    padding: '3px 12px',
    borderRadius: '70px',
  },
}));

export default useStyles;
